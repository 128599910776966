import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import LandingPage from "./components/LandingPage";
import LoginPage from "./pages/LoginPage";
import SignUpPage from "./pages/SignUpPage";
import UserPage from "./pages/UserPage";
import Navbar from "./components/Navbar";
import { auth } from "./firebaseConfig";
import { onAuthStateChanged } from "firebase/auth";
import ProfilePage from "./pages/ProfilePage";

function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      console.log("Auth State Changed: ", currentUser);
      setUser(currentUser);
    });

    return () => unsubscribe(); // Cleanup subscription on unmount
  }, []);

  return (
    <Router>
      <Navbar user={user} setUser={setUser} />
      <Routes>
        {/* Redirect users based on their authentication status */}
        <Route path="/" element={!user ? <LandingPage /> : <Navigate to="/user" />} />
        <Route path="/login" element={!user ? <LoginPage /> : <Navigate to="/user" />} />
        <Route path="/signup" element={!user ? <SignUpPage /> : <Navigate to="/user" />} />
        <Route path="/user" element={user ? <UserPage /> : <Navigate to="/" />} />
        
        <Route path="/profile" element={user ? <ProfilePage /> : <Navigate to="/profile" />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
}

export default App;
