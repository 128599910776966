import React, { useState } from "react";
import { Box, TextField, Button, Typography, RadioGroup, FormControlLabel, Radio, FormLabel, Alert } from "@mui/material";
import { doc, setDoc } from "firebase/firestore";
import { db, auth } from "../firebaseConfig"; // Firestore setup

const ProfilePage = () => {
  const [formData, setFormData] = useState({
    name: "",
    preferences: "",
    availability: "",
    location: "",
    role: "parent", // Default role as 'parent'
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleRoleChange = (e) => {
    setFormData({ ...formData, role: e.target.value });
  };

  const handleSubmit = async () => {
    const userId = auth.currentUser?.uid;
    if (!userId) {
      setError("User is not authenticated.");
      return;
    }

    try {
      // Save user profile to Firestore
      await setDoc(doc(db, "userProfiles", userId), formData);
      setSuccess(true);
      setError("");
    } catch (err) {
      console.error("Profile Save Error:", err.message);
      setError("Failed to save profile. Please try again.");
    }
  };

  return (
    <Box
      sx={{
        maxWidth: 600,
        mx: "auto",
        p: 3,
        mt: 5,
        borderRadius: 2,
        boxShadow: "0px 4px 10px rgba(0,0,0,0.1)",
        bgcolor: "white",
      }}
    >
      <Typography variant="h5" sx={{ mb: 3, fontWeight: "bold" }}>
        Complete Your Profile
      </Typography>
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}
      {success && (
        <Alert severity="success" sx={{ mb: 2 }}>
          Profile saved successfully!
        </Alert>
      )}

      <TextField
        label="Name"
        name="name"
        fullWidth
        sx={{ mb: 2 }}
        value={formData.name}
        onChange={handleChange}
      />
      <TextField
        label="Preferences"
        name="preferences"
        fullWidth
        sx={{ mb: 2 }}
        value={formData.preferences}
        onChange={handleChange}
      />
      <TextField
        label="Availability"
        name="availability"
        fullWidth
        sx={{ mb: 2 }}
        value={formData.availability}
        onChange={handleChange}
      />
      <TextField
        label="Location"
        name="location"
        fullWidth
        sx={{ mb: 3 }}
        value={formData.location}
        onChange={handleChange}
      />

      {/* Role Selection */}
      <FormLabel component="legend" sx={{ mb: 1 }}>
        I am a:
      </FormLabel>
      <RadioGroup
        row
        name="role"
        value={formData.role}
        onChange={handleRoleChange}
        sx={{ mb: 3 }}
      >
        <FormControlLabel value="parent" control={<Radio />} label="Parent" />
        <FormControlLabel value="caregiver" control={<Radio />} label="Caregiver" />
      </RadioGroup>

      <Button variant="contained" onClick={handleSubmit} fullWidth>
        Save Profile
      </Button>
    </Box>
  );
};

export default ProfilePage;
