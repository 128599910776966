import React from "react";
import { Box, Typography, Button } from "@mui/material";

const UserPage = () => {
  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        background: "#f5f5f5",
      }}
    >
      <Typography variant="h3" sx={{ mb: 2 }}>
        Welcome, User!
      </Typography>
      <Typography variant="h5" sx={{ mb: 4 }}>
        Explore your dashboard and get started.
      </Typography>
      <Button variant="contained" color="primary" size="large">
        Go to Dashboard
      </Button>
    </Box>
  );
};

export default UserPage;
