import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  Typography,
  Box,
  Modal,
  Tabs,
  Tab,
  TextField,
  Alert,
  IconButton,
  CircularProgress,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import GoogleIcon from "@mui/icons-material/Google";
import FacebookIcon from "@mui/icons-material/Facebook";
import { useNavigate } from "react-router-dom";
import {
  auth,
  signUpWithEmail,
  logInWithEmail,
  logInWithGoogle,
  logInWithFacebook,
} from "../firebaseConfig";
import { getDoc, doc } from "firebase/firestore";
import { db } from "../firebaseConfig";

const Navbar = ({ user, setUser }) => {
  const [scrolled, setScrolled] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [activeTab, setActiveTab] = useState(0); // 0 for Login, 1 for Sign Up
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false); // Loading indicator for actions
  const [mobileMenu, setMobileMenu] = useState(false); // Mobile menu toggle
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleOpenModal = (tabIndex) => {
    setActiveTab(tabIndex);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setEmail("");
    setPassword("");
    setError("");
  };


const handleEmailAction = async () => {
  setLoading(true);
  try {
    let authenticatedUser;
    if (activeTab === 0) {
      // Login
      const loggedInUser = await logInWithEmail(email, password);
      authenticatedUser = loggedInUser.user;
    } else {
      // Sign Up
      const newUser = await signUpWithEmail(email, password);
      authenticatedUser = newUser.user;

      // New users should go to the profile creation page
      navigate("/profile");
    }
    setUser(authenticatedUser); // Update user state
    handleCloseModal();
  } catch (error) {
    setError(error.message);
  } finally {
    setLoading(false);
  }
};

const handleGoogleLogin = async () => {
    setLoading(true);
    try {
      const result = await logInWithGoogle();
      const user = result.user;
      setUser(user);
  
      // Check if the user profile exists in Firestore
      const userProfileRef = doc(db, "userProfiles", user.uid);
      const userProfile = await getDoc(userProfileRef);
      console.log(userProfile);
      console.log(userProfile.exists());
  
      if (userProfile.exists()) {
        navigate("/user"); // Existing user
      } else {
        navigate("/profile"); // New user, navigate to profile creation
      }
  
      handleCloseModal();
    } catch (error) {
      console.error("Google Login Error:", error.message);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

const handleFacebookLogin = async () => {
  setLoading(true);
  try {
    const result = await logInWithFacebook();
    setUser(result.user);

    // Check if the user is signing up for the first time
    const isNewUser = result._tokenResponse?.isNewUser;
    if (isNewUser) {
      navigate("/profile"); // Navigate to profile creation page
    } else {
      navigate("/user"); // Navigate to user dashboard
    }

    handleCloseModal();
  } catch (error) {
    setError(error.message);
  } finally {
    setLoading(false);
  }
};
  

  const handleLogout = async () => {
    try {
      await auth.signOut();
      setUser(null);
      navigate("/");
    } catch (error) {
      console.error("Logout Error:", error.message);
    }
  };

  return (
    <>
      <AppBar
        position="sticky"
        sx={{
          backgroundColor: scrolled ? "rgba(255, 255, 255, 0.95)" : "rgba(255, 255, 255, 0.5)",
          backdropFilter: "blur(10px)",
          boxShadow: scrolled ? "0px 4px 15px rgba(0, 0, 0, 0.2)" : "none",
          transition: "all 0.3s ease-in-out",
          color: "#000",
        }}
      >
        <Toolbar sx={{ justifyContent: "space-between" }}>
          {/* Brand Logo */}
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <img src="/logo.png" alt="Logo" style={{ height: "40px", marginRight: "10px" }} />
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              PickNcare
            </Typography>
          </Box>

          {/* Desktop Navigation */}
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            {!user ? (
              <>
                <Button onClick={() => handleOpenModal(0)} sx={{ mx: 1 }}>
                  Login
                </Button>
                <Button onClick={() => handleOpenModal(1)} variant="outlined" sx={{ mx: 1 }}>
                  Sign Up
                </Button>
              </>
            ) : (
              <Button onClick={handleLogout} sx={{ mx: 1 }}>
                Sign Out
              </Button>
            )}
          </Box>

          {/* Mobile Menu Button */}
          <IconButton
            onClick={() => setMobileMenu(!mobileMenu)}
            sx={{ display: { xs: "block", md: "none" } }}
          >
            {mobileMenu ? <CloseIcon /> : <MenuIcon />}
          </IconButton>
        </Toolbar>
      </AppBar>

      {/* Mobile Menu */}
      {mobileMenu && (
        <Box
          sx={{
            position: "absolute",
            top: "64px",
            width: "100%",
            bgcolor: "white",
            boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
            display: { xs: "block", md: "none" },
          }}
        >
          {!user ? (
            <>
              <Button
                onClick={() => {
                  handleOpenModal(0);
                  setMobileMenu(false);
                }}
                fullWidth
                sx={{ py: 1 }}
              >
                Login
              </Button>
              <Button
                onClick={() => {
                  handleOpenModal(1);
                  setMobileMenu(false);
                }}
                fullWidth
                variant="outlined"
                sx={{ py: 1 }}
              >
                Sign Up
              </Button>
            </>
          ) : (
            <Button onClick={handleLogout} fullWidth sx={{ py: 1 }}>
              Sign Out
            </Button>
          )}
        </Box>
      )}

      {/* Login/Sign Up Modal */}
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            width: 400,
            bgcolor: "white",
            p: 4,
            mx: "auto",
            mt: "10%",
            borderRadius: 2,
            boxShadow: 24,
            position: "relative",
          }}
        >
          <Tabs
            value={activeTab}
            onChange={(e, newValue) => setActiveTab(newValue)}
            variant="fullWidth"
            sx={{ mb: 3 }}
          >
            <Tab label="Login" />
            <Tab label="Sign Up" />
          </Tabs>
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}
          {loading ? (
            <Box sx={{ textAlign: "center" }}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <TextField
                label="Email"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                sx={{ mb: 2 }}
              />
              <TextField
                label="Password"
                type="password"
                fullWidth
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                sx={{ mb: 2 }}
              />
              <Button onClick={handleEmailAction} variant="contained" fullWidth>
                {activeTab === 0 ? "Login" : "Sign Up"}
              </Button>
              <Box sx={{ mt: 2, textAlign: "center" }}>
                <Button
                  onClick={handleGoogleLogin}
                  variant="contained"
                  startIcon={<GoogleIcon />}
                  sx={{ bgcolor: "#4285F4", mb: 1 }}
                >
                  Login with Google
                </Button>
                <Button
                  onClick={handleFacebookLogin}
                  variant="contained"
                  startIcon={<FacebookIcon />}
                  sx={{ bgcolor: "#3b5998" }}
                >
                  Login with Facebook
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default Navbar;
