import React, { useState } from "react";
import {
  Box,
  Typography,
  Tabs,
  Tab,
  Grid,
  TextField,
  InputAdornment,
  Button,
  Container,
  Paper,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import StarIcon from "@mui/icons-material/Star";
import PeopleIcon from "@mui/icons-material/People";
import VerifiedIcon from "@mui/icons-material/Verified";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";

const LandingPage = () => {
  const [activeTab, setActiveTab] = useState(0); // 0 for "Find care", 1 for "Find a job"
  const [zipCode, setZipCode] = useState("");

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleSearch = () => {
    if (zipCode.trim() === "") {
      alert("Please enter a ZIP code.");
      return;
    }
    alert(
      `Searching for ${activeTab === 0 ? "care services" : "jobs"} in ZIP code ${zipCode}`
    );
  };

  return (
    <Box>
      {/* Hero Section */}
      <Box
        sx={{
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundImage: `url('https://incentz.com/wp-content/uploads/2021/06/How-Caregivers-Responsibilities-for-Babies-or-Kids.jpg')`, // Replace with your image URL
            backgroundSize: "cover",
            backgroundPosition: "center",
            color: "white",
            textAlign: "center",
        }}
        >
        <Typography variant="h2" sx={{ mb: 2 }}>
            Connecting families with
        </Typography>
        <Typography variant="h2" sx={{ mb: 4 }}>
            quality, local caregivers
        </Typography>

        {/* Tab Section */}
        <Box sx={{ maxWidth: "600px", mx: "auto" }}>
            <Tabs
            value={activeTab}
            onChange={handleTabChange}
            variant="fullWidth"
            textColor="primary"
            indicatorColor="primary"
            sx={{
                backgroundColor: "white",
                borderRadius: "8px",
                mb: 3,
            }}
            >
            <Tab label="Find care" />
            <Tab label="Find a job" />
            </Tabs>

            {/* Search Section */}
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12}>
                    <TextField
                    fullWidth
                    placeholder="ZIP code"
                    value={zipCode}
                    onChange={(e) => setZipCode(e.target.value)}
                    InputProps={{
                        startAdornment: (
                        <InputAdornment position="start">
                            <LocationOnIcon sx={{ color: "#213f5f" }} /> {/* Icon color */}
                        </InputAdornment>
                        ),
                    }}
                    sx={{
                        "& .MuiInputBase-root": {
                        color: "#213f5f", // Text color inside the input
                        backgroundColor: "white", // Solid white background
                        borderRadius: "4px",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#ddd", // Subtle border color
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#bbb", // Slightly darker border on hover
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#6a11cb", // Highlighted border when focused
                        },
                        "& .MuiInputBase-input::placeholder": {
                        color: "#999", // Placeholder color
                        },
                    }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button
                    variant="contained"
                    size="large"
                    fullWidth
                    onClick={handleSearch}
                    sx={{
                        color: "white", // Text color
                        backgroundColor: "#6a11cb", // Button background
                        "&:hover": {
                        backgroundColor: "#5a0fab", // Darker shade on hover
                        },
                        fontWeight: "bold",
                    }}
                    >
                    Search
                    </Button>
                </Grid>
                </Grid>


        </Box>
        </Box>


      {/* Features Section */}
      <Box sx={{ py: 8, background: "#f5f5f5" }}>
        <Container>
          <Typography variant="h4" align="center" sx={{ mb: 4 }}>
            Features
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={4}>
              <Paper elevation={3} sx={{ p: 5, textAlign: "center", height: "250px" }}>
                <StarIcon sx={{ fontSize: 50, color: "#6a11cb", mb: 2 }} />
                <Typography variant="h6">Premium Caregivers</Typography>
                <Typography>
                  Access the best caregivers who are highly rated and reliable.
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Paper elevation={3} sx={{ p: 5, textAlign: "center", height: "250px" }}>
                <PeopleIcon sx={{ fontSize: 50, color: "#2575fc", mb: 2 }} />
                <Typography variant="h6">Community Focused</Typography>
                <Typography>
                  Our services connect you with local professionals in your area.
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Paper elevation={3} sx={{ p: 5, textAlign: "center", height: "250px" }}>
                <VerifiedIcon sx={{ fontSize: 50, color: "#4caf50", mb: 2 }} />
                <Typography variant="h6">Verified Profiles</Typography>
                <Typography>
                  All caregivers are thoroughly vetted for your peace of mind.
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Pricing Section */}
      <Box sx={{ py: 8 }}>
        <Container>
          <Typography variant="h4" align="center" sx={{ mb: 4 }}>
            Pricing
          </Typography>
          <Grid container spacing={4} justifyContent="center">
            <Grid item xs={12} sm={4}>
              <Paper
                elevation={3}
                sx={{ p: 5, textAlign: "center", height: "300px" }}
              >
                <AttachMoneyIcon sx={{ fontSize: 50, color: "#6a11cb", mb: 2 }} />
                <Typography variant="h6">Basic Plan</Typography>
                <Typography variant="h4" sx={{ mt: 2 }}>
                  $10
                </Typography>
                <Typography variant="body1">per month</Typography>
                <Button variant="contained" color="primary" sx={{ mt: 4 }}>
                  Subscribe
                </Button>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Paper
                elevation={3}
                sx={{ p: 5, textAlign: "center", height: "300px" }}
              >
                <AttachMoneyIcon sx={{ fontSize: 50, color: "#2575fc", mb: 2 }} />
                <Typography variant="h6">Pro Plan</Typography>
                <Typography variant="h4" sx={{ mt: 2 }}>
                  $30
                </Typography>
                <Typography variant="body1">per month</Typography>
                <Button variant="contained" color="primary" sx={{ mt: 4 }}>
                  Subscribe
                </Button>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Paper
                elevation={3}
                sx={{ p: 5, textAlign: "center", height: "300px" }}
              >
                <AttachMoneyIcon sx={{ fontSize: 50, color: "#4caf50", mb: 2 }} />
                <Typography variant="h6">Enterprise Plan</Typography>
                <Typography variant="h4" sx={{ mt: 2 }}>
                  Custom Pricing
                </Typography>
                <Typography variant="body1">tailored solutions</Typography>
                <Button variant="contained" color="primary" sx={{ mt: 4 }}>
                  Contact Us
                </Button>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>


      {/* Contact Section */}
      <Box sx={{ py: 5, background: "#f5f5f5" }}>
        <Container>
          <Typography variant="h4" align="center" sx={{ mb: 4 }}>
            Contact Us
          </Typography>
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={6}>
              <TextField label="Name" fullWidth sx={{ mb: 2 }} />
              <TextField label="Email" fullWidth sx={{ mb: 2 }} />
              <TextField
                label="Message"
                fullWidth
                multiline
                rows={4}
                sx={{ mb: 2 }}
              />
              <Button variant="contained" color="primary" fullWidth>
                Submit
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Footer Section */}
{/* Footer Section */}
<Box
  sx={{
    py: 3,
    background: "#333",
    color: "white",
    textAlign: "center",
  }}
>
  <Typography variant="body2">© 2024 PickNcare. All rights reserved.</Typography>
  <Typography variant="body2">
    Follow us on
    <button
      onClick={() => alert("Navigate to Facebook")}
      style={{
        background: "none",
        border: "none",
        color: "#fff",
        cursor: "pointer",
        textDecoration: "underline",
        marginLeft: "5px",
      }}
    >
      Facebook
    </button>
    ,
    <button
      onClick={() => alert("Navigate to Twitter")}
      style={{
        background: "none",
        border: "none",
        color: "#fff",
        cursor: "pointer",
        textDecoration: "underline",
        marginLeft: "5px",
      }}
    >
      Twitter
    </button>
    , and
    <button
      onClick={() => alert("Navigate to Instagram")}
      style={{
        background: "none",
        border: "none",
        color: "#fff",
        cursor: "pointer",
        textDecoration: "underline",
        marginLeft: "5px",
      }}
    >
      Instagram
    </button>
  </Typography>
</Box>


    </Box>
  );
};

export default LandingPage;
