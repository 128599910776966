
  
  import { initializeApp } from "firebase/app";
  import {
    getAuth,
    GoogleAuthProvider,
    FacebookAuthProvider,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signInWithPopup,
  } from "firebase/auth";

import { getFirestore } from "firebase/firestore";
  
  const firebaseConfig = {
    apiKey: "AIzaSyA5iHf4R-_wZSZ7Nu_BEn4ld8fFWuP1YfU",
    authDomain: "pickncare.firebaseapp.com",
    projectId: "pickncare",
    storageBucket: "pickncare.firebasestorage.app",
    messagingSenderId: "373761581209",
    appId: "1:373761581209:web:ce521b46776a533f659808",
    measurementId: "G-08PMR7Y67G"
  };
  const app = initializeApp(firebaseConfig);
  export const auth = getAuth(app);

export const db = getFirestore(app); // Add this line to initialize Firestore
  export const googleProvider = new GoogleAuthProvider();
  export const facebookProvider = new FacebookAuthProvider();
  
  // Sign Up with Email and Password
  export const signUpWithEmail = async (email, password) => {
    return createUserWithEmailAndPassword(auth, email, password);
  };
  
  // Login with Email and Password
  export const logInWithEmail = async (email, password) => {
    return signInWithEmailAndPassword(auth, email, password);
  };
  
  // Login with Google
  export const logInWithGoogle = async () => {
    return signInWithPopup(auth, googleProvider);
  };
  
  // Login with Facebook
  export const logInWithFacebook = async () => {
    return signInWithPopup(auth, facebookProvider);
  };
  

  